import { useState, useEffect } from 'react';
import DeviceTable from '../components/DeviceTable';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Switch, Route, useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { fetchDevices } from '../features/devices/devicesSlice';
import SingleDevicePage from './SingleDevicePage';


function DevicesPage() {
  const devices = useSelector(state => state.devices.devices);
  const devicesStatus = useSelector(state => state.devices.status);
  const dispatch = useDispatch();
  const history = useHistory()

  useEffect(() => {
    if (devicesStatus === 'idle') {
      dispatch(fetchDevices());
    }
  });

  const [filterParams, setFilterParams] = useState({
    deviceIdSearch: "",
    includeOnline: true,
    includeOffline: true
  })

  let match = useRouteMatch();

  function filterDevices() {
    const filteredDevices = devices.filter((d) => {
      if (d.device_id.includes(filterParams.deviceIdSearch)) {
        if (d.connection_status === 'offline' && filterParams.includeOffline)
          return true;
        if (d.connection_status === 'online' && filterParams.includeOnline)
          return true;
      }
      return false;
    });
    
    return filteredDevices;
  }

  return (
    <>
      <Switch>
        <Route path={`${match.path}/:deviceId`}>
          <SingleDevicePage/>
        </Route>
        <Route path={match.path}>
          <Row className="m-3 align-items-center">
            <div className="col-md-8 col-sm-12">
              <Form.Group controlId="deviceId">
                <Form.Control name="deviceId" type="text" placeholder="Filter devices..."
                  onChange={
                    (e) => {
                      setFilterParams({
                        ...filterParams,
                        deviceIdSearch: e.target.value
                      })
                    }
                  }
                />
              </Form.Group>
            </div>
            <div className="col-lg-2 col-md-6">
              <Form.Group controlId="online">
                <Form.Check 
                  custom
                  type="checkbox" id="online" label="Online" checked={filterParams.includeOnline}
                  onChange={
                    (e) => { 
                      setFilterParams({
                          ...filterParams,
                          includeOnline: e.target.checked
                        }
                      )
                    }
                  }
                />
              </Form.Group>
            </div>
            <div className="col-lg-2 col-md-6">
              <Form.Group controlId="offline">
                <Form.Check 
                  custom
                  type="checkbox" id="offline" label="Offline" checked={filterParams.includeOffline}
                  onChange={
                    (e) => { 
                      setFilterParams({
                          ...filterParams,
                          includeOffline: e.target.checked
                        }
                      )
                    }
                  }
                />
              </Form.Group>
            </div>
          </Row>
          <Row className="m-3">
            <DeviceTable devices={filterDevices()}/>
          </Row>
          <Row className="m-3">
            <Button size="lg" variant="outline-primary" onClick={() => history.push('/claim')}>
              ADD NEW DEVICE
            </Button>
          </Row>
        </Route>
      </Switch>
    </>
  );
}

export default DevicesPage;
