import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import LoadingButton from '../components/LoadingButton';
import { useSelector, useDispatch } from 'react-redux';
import { updateOrgData } from '../features/profile/profileSlice';
import country from 'country-list-js';
import SelectSearch, {fuzzySearch} from 'react-select-search';
import { useState } from 'react';


function OrganizationPage() {
  const dispatch = useDispatch();
  const org = useSelector(state => state.profile.org);
  const org_status = useSelector(state => state.profile.org_status);

  const [ selectedCountry, setCountry ] = useState(null)

  const countries = country.names();
  const countryOptions = countries.map(c => ({
    name: c,
    value: country.findByName(c).code.iso2
  }))

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    const data = {
      org_name: formData.get('org_name'),
      contact_email: formData.get('contact_email'),
      contact_phone: formData.get('contact_phone'),
      location: {
        address_line1: formData.get('address_line1'),
        address_line2: formData.get('address_line2'),
        city: formData.get('city'),
        zipcode: formData.get('zip'),
        country: selectedCountry
      }
    }
    dispatch(updateOrgData({org: data}))
  }

  return (
    <div className="m-5" style={{maxWidth: "800px"}}>
      <Card className="p-5 bg-light">
        <h2>Organization</h2>
        { org === null ? (
          <Spinner variant="secondary" className="my-4 mx-auto" animation="border" role="status"/>
        ) : <>
          <p>{org.org_name} <i>{org.org_number}</i></p>
          <hr></hr>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Org Name</Form.Label>
              <Form.Control name="org_name" required defaultValue={org.org_name}></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Contact email</Form.Label>
              <Form.Control name="contact_email" type="email" required defaultValue={org.contact_email}></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Contact phone</Form.Label>
              <Form.Control name="contact_phone" type="tel" required defaultValue={org.contact_phone}></Form.Control>
            </Form.Group>
            <h3>Address</h3>
            <Form.Group>
              <Form.Label>Address line 1</Form.Label>
              <Form.Control name="address_line1" required defaultValue={org.location.address_line1}></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Address line 2</Form.Label>
              <Form.Control name="address_line2" required defaultValue={org.location.address_line2}></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>City</Form.Label>
              <Form.Control name="city" required defaultValue={org.location.city}></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Zip/Postal code</Form.Label>
              <Form.Control name="zip" required defaultValue={org.location.zipcode}></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Country</Form.Label>
              <SelectSearch
                name="country"
                options={countryOptions}
                search={true}
                filterOptions={fuzzySearch}
                onChange={setCountry}
                value={selectedCountry}
                placeholder="Choose country"
              ></SelectSearch>
            </Form.Group>
            <LoadingButton size="lg" variant="primary" isloading={org_status === 'loading'} type="submit">
              SAVE
            </LoadingButton>
          </Form>
        </>}
      </Card>
    </div>
  )
}

export default OrganizationPage;
