import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import EventsTable from '../components/EventsTable';
import { fetchDevices } from '../features/devices/devicesSlice';
import { API } from 'aws-amplify';


function HomePage() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.profile.user);
  const deviceStatus = useSelector(state => state.devices.status);
  const devices = useSelector(state => state.devices.devices);
  const [events, setEvents] = useState([]);

  const onlineDeviceCount = devices.reduce((a, x) => (x.connection_status === 'online' ? a+1 : a), 0);

  useEffect(() => {
    if (deviceStatus === 'idle') {
      dispatch(fetchDevices());
    }
  }, [])

  // Fetch latest events
  // Do this outside of redux, since the events managed by redux are
  // dependentant on the filter parameters etc.
  useEffect(async () => {
    const response = await API.get('ilabcloud', '/events?page_size=10');
    setEvents(response.events);
  }, []);

  return (
    <div className="">
      <Row>
        { user && ( 
          <Col>
            <h4>Welcome {user.name}!</h4>
            <p>Manage your Industrilas cloud devices with the links to the left.</p>
          </Col>
        )}
      </Row>
      <Row className="justify-content-around">
        <Col md={12} lg={3} className="mb-3">
          <Card className="bg-light">
            <Card.Body>
              <Card.Title>Device Overview</Card.Title>
              <p><strong>Devices: </strong> {devices.length}</p>
              <p><strong>Online: </strong> {onlineDeviceCount}</p>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={8} md={12}>
          <Card className="bg-light">
            <Card.Body>
              <Card.Title>Recent events</Card.Title>
              <EventsTable events={events} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default HomePage;
