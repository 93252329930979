import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { callAPI } from '../../api';
import { signOutUser } from '../profile/profileSlice';

const initialState = {
  webhooks: [],
  status: 'idle'
}

export const fetchWebhooks = createAsyncThunk('webhooks/fetchWebhooks', async (_, thunkAPI) => {
  const request = {
    path: '/webhooks',
  }
  const response = await callAPI(request, thunkAPI);
  return response.webhooks;
});

export const createWebhook = createAsyncThunk('webhooks/createWebhook', async (arg, thunkAPI) => {
  const request = {
    path: '/webhooks/new',
    method: 'post',
    body: {
      callback_url: arg.url,
      device_id: arg.deviceId,
      event_type: arg.eventType,
      headers: arg.headers
    }
  }
  const response = await callAPI(request, thunkAPI);
  return response;
})

export const disableWebhook = createAsyncThunk('webhooks/disableWebhook', async (arg, thunkAPI) => {
  const request = {
    path: `/webhooks/${arg}/disable`,
    method: 'post'
  }
  return await callAPI(request, thunkAPI);
});

export const enableWebhook = createAsyncThunk('webhooks/enableWebhook', async (arg, thunkAPI) => {
  const request = {
    path: `/webhooks/${arg}/enable`,
    method: 'post'
  }
  return await callAPI(request, thunkAPI);
});

export const deleteWebhook = createAsyncThunk('webhooks/deleteWebhook', async (arg, thunkAPI) => {
  const request = {
    path: `/webhooks/${arg}`,
    method: 'delete'
  }
  return await callAPI(request, thunkAPI);
});

const webhooksSlice = createSlice({
  name: "webhooks",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchWebhooks.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchWebhooks.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.webhooks = action.payload;
    },
    [fetchWebhooks.error]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [createWebhook.fulfilled]: (state, action) => {
      state.webhooks.push(action.payload);
    },
    [disableWebhook.pending]: (state, action) => {
      state.webhooks.find(w => w.webhook_id === action.meta.arg).loading = true;
    },
    [enableWebhook.pending]: (state, action) => {
      state.webhooks.find(w => w.webhook_id === action.meta.arg).loading = true;
    },
    [disableWebhook.fulfilled]: (state, action) => {
      state.webhooks.find(w => w.webhook_id === action.meta.arg).active = false;
      state.webhooks.find(w => w.webhook_id === action.meta.arg).loading = false;
    },
    [enableWebhook.fulfilled]: (state, action) => {
      state.webhooks.find(w => w.webhook_id === action.meta.arg).active = true;
      state.webhooks.find(w => w.webhook_id === action.meta.arg).loading = false;
    },
    [deleteWebhook.pending]: (state, action) => {
      state.webhooks.find(w => w.webhook_id === action.meta.arg).loading = true;
    },
    [deleteWebhook.fulfilled]: (state, action) => {
      const i = state.webhooks.findIndex(w => w.webhook_id === action.meta.arg);
      state.webhooks.splice(i, 1)
    },
    [signOutUser.fulfilled]: () => {
      return initialState;
    }
  }
});


export default webhooksSlice.reducer;
