import {
  Route,
  Redirect,
} from "react-router-dom";

function AuthRoute({children, authenticated, ...rest}) {
  return (
    <Route 
      {...rest}
      render={() =>
          authenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login"
              }}
            />
          )
        }
    />
  );
}

export default AuthRoute;

