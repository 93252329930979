import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { callAPI } from '../../api';
import { signOutUser } from '../profile/profileSlice';

// For now this slice will only manage fetching the main device list.

const initialState = {
  devices: [],
  status: 'idle',
  error: null,
}

export const fetchDevices = createAsyncThunk('devices/fetchDevices', async(_, thunkAPI) => {
  const request = {
    path: '/devices'
  }
  const response = await callAPI(request, thunkAPI);
  return response;
})

const devicesSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {

  },
  extraReducers: {
    [fetchDevices.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchDevices.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.devices = action.payload;
    },
    [signOutUser.fulfilled]: () => {
      return initialState;
    }
  }
});

export default devicesSlice.reducer;
