import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Spinner from 'react-bootstrap/Spinner';

import UsersTable from '../features/users/UsersTable';
import InviteUserForm from '../features/users/InviteUserForm';
import ShowForRole from '../components/ShowForRole';

import { fetchUsers } from '../features/users/usersSlice';



function UsersPage() {
  const dispatch = useDispatch();
  
  const users = useSelector(state => state.users.users);
  const usersStatus = useSelector(state => state.users.status);

  useEffect(() => {
    if (usersStatus === 'idle') {
      dispatch(fetchUsers());
    }
  }, [usersStatus, dispatch]);

  let table;
  if (usersStatus === 'succeeded') {
    table = <UsersTable users={users} />;
  } else {
    table = (
      <div className="row">
        <div className="d-flex table-border w-100 bg-light align-items-center">
          <Spinner className="mx-auto my-5" animation="border"/>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="m-5 mt-5">
        {table}
      </div>
      <div className="m-5 mt-5">
        <ShowForRole admin>
          <InviteUserForm />
        </ShowForRole>
      </div>
    </>
  );
}

export default UsersPage;
