import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

import LoadingButton from '../components/LoadingButton';

function ResetPasswordPage() {

  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(e.target);
    const email = formData.get('email');
    Auth.forgotPassword(email)
      .then((data) => {
        console.log(data)
        history.push(`/confirm-password-reset?username=${email}`);
      })
  }

  return (
    <div className="card p-4 form-max-width">
      <h3>Reset password</h3>
      <Form onSubmit={onSubmit}>
        <Form.Group controlId="email">
          <Form.Label>Email address</Form.Label>
          <Form.Control required name="email" type="email" placeholder="enter email"/>
          <Form.Text className="text-muted">
            Enter your account email to reset your password.
          </Form.Text>
        </Form.Group>
        <LoadingButton isloading={loading} size="lg" type="submit">RESET</LoadingButton>
      </Form>
    </div>
  );
}

export default ResetPasswordPage;
