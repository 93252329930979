import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';

import RoleDropdown from './RoleDropdown';
import ShowForRole from '../../components/ShowForRole';

function UsersTable(props) {
  const users = props.users;

  return (
    <Row>
      <div className="table-border w-100">
        <Table className="mb-0 table-responsive-stack table-striped">
          <thead>
            <tr className="d-flex">
              <th className="col font-weight-normal">EMAIL</th>
              <th className="col font-weight-normal">NAME</th>
              <th className="col font-weight-normal">ROLE</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.user_id} className="d-flex">
                <td className="col font-weight-light"><span>{user.email}</span></td>
                <td className="col font-weight-light"><span>{user.name}</span></td>
                <td className="col font-weight-light">
                  <ShowForRole admin>
                    <RoleDropdown user={user} />
                  </ShowForRole>
                  <ShowForRole installer developer readonly>
                    {user.role}
                  </ShowForRole>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Row>
  );
}

export default UsersTable;
