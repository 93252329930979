import Table from 'react-bootstrap/Table';
import {CircleFill} from 'react-bootstrap-icons';
import { useRouteMatch, useHistory } from 'react-router-dom';

function DeviceTable(props) {
  const devices = props.devices;
  let match = useRouteMatch();
  let history = useHistory();

  const handleRowClick = (id) => {
    history.push(`${match.url}/${id}`);
  }

  return (
    <div className="table-border w-100 bg-light">
      <Table hover className="mb-0">
        <thead>
          <tr className="d-flex">
            <th className="col-md-6 col-lg-7 col-xl-8">DEVICE ID</th>
            <th className="col-md-3 col-lg-2">TYPE</th>
            <th className="col-md-3 col-lg-3 col-xl-2">CONNECTION</th>
          </tr>
        </thead>
        <tbody>
          {devices.map((d) => (
            <tr 
              key={d.device_id} 
              className="d-flex clickable-row" 
              onClick={() => handleRowClick(d.device_id)}
            >
              <td className="col-md-6 col-lg-7 col-xl-8 font-weight-light">{d.device_id}</td>
              <td className="col-md-3 col-lg-2 font-weight-light">VMAX</td>
              <td className="col-md-3 col-lg-3 col-xl-2 font-weight-light">
                <CircleFill color={d.connection_status==='online' ? "green" : "red"} className="mr-2"/>
                {d.connection_status}
              </td>
            </tr>
            )
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default DeviceTable;
