import { useDispatch, useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import SelectSearch, {fuzzySearch} from 'react-select-search';

import EventsTable from '../components/EventsTable';
import { 
  changeEventType, 
  changePageSize,
  changeStartDate,
  changeEndDate,
  changeDeviceId,
  clearAllFilters,
  fetchPrevPage,
  fetchNextPage,
  selectCurrentPage,
  selectHasPrev,
  selectFilterParams
} from '../features/events/eventsSlice';

const formatDateString = (d) => {
  if (d === null) return "";
  const date = new Date(d);
  return date.toISOString().slice(0, 10);
}

function EventsPage() {
  const dispatch = useDispatch();
  const events = useSelector(selectCurrentPage);
  const requestStatus = useSelector(state => state.events.status);
  const hasNext = useSelector(state => state.events.hasNext);
  const hasPrev = useSelector(selectHasPrev);
  const filterParams = useSelector(selectFilterParams);
  const devices = useSelector(state => state.devices.devices);

  if (requestStatus === 'idle') {
    dispatch(fetchNextPage());
  }

  const onChangePageSize = (e) => {
    dispatch(changePageSize(e));
  }

  const onChangeEventType = (e) => {
    dispatch(changeEventType(e));
  }

  const onChangeStartDate = (e) => {
    const date = new Date(e.target.value).toISOString().replace('Z', '+00:00');
    dispatch(changeStartDate(date));
  }

  const onChangeEndDate = (e) => {
    const date = new Date(e.target.value).toISOString().replace('Z', '+00:00');
    dispatch(changeEndDate(date));
  }

  const onChangeDevice = (e) => {
    dispatch(changeDeviceId(e));
  }
  
  const deviceOptions = devices.map(d => ({
    name: d.device_id,
    value: d.device_id
  }));

  const eventOptions = [
    {name: 'All', value: 'all'},
    {name: 'Connection status', value:'connection_status_update'},
    {name: 'Lock status', value:'lock_status_update'},
  ]

  const pageOptions = [
    {name: '10', value: 10},
    {name: '25', value: 25},
    {name: '50', value: 50},
    {name: '100', value: 100},
  ]

  return (
    <>
      <Row className="mx-5 ">
        <h2>Events</h2>
      </Row>
      <Row className="mx-5 mt-5">
        <div className="col-xl-6 col-lg-12">
          <Form.Group>
            <Form.Label className="text-muted">device ID</Form.Label>
            <SelectSearch 
              options={deviceOptions} 
              filterOptions={fuzzySearch}
              search={true}
              placeholder="Select a device"
              onChange={onChangeDevice}
              value={filterParams.deviceId}
            />
          </Form.Group>
        </div>
        <div className="col-xl-3 col-lg-6">
          <Form.Group controlId="startDate">
            <Form.Label className="text-muted">start date</Form.Label>
            <Form.Control onChange={onChangeStartDate} name="startDate" type="date" value={formatDateString(filterParams.startDate)}/>
          </Form.Group>
        </div>
        <div className="col-xl-3 col-lg-6">
          <Form.Group controlId="endDate">
            <Form.Label className="text-muted">end date</Form.Label>
            <Form.Control onChange={onChangeEndDate} name="endDate" type="date" value={formatDateString(filterParams.endDate)}/>
          </Form.Group>
        </div>
      </Row>
      <Row className="mx-5 align-items-center">
        <div className="col-lg-4 col-md-6">
          <Form.Group controlId="eventType">
            <Form.Label className="text-muted">event type</Form.Label>
            <SelectSearch options={eventOptions} value={filterParams.eventType} onChange={onChangeEventType}/>
          </Form.Group>
        </div>
        <div className="col-lg-4 col-md-6">
          <Form.Group controlId="pageSize">
            <Form.Label className="text-muted">page size</Form.Label>
            <SelectSearch options={pageOptions} value={filterParams.pageSize} onChange={onChangePageSize}/>
          </Form.Group>
        </div>
        <div className="col-2">
          <Button onClick={() => dispatch(clearAllFilters())}size="lg" variant="outline-primary">CLEAR</Button>
        </div>
      </Row>
      <Row className="m-2 mt-5">
        <div className="col">
          <EventsTable loading={requestStatus === 'loading'} events={events}/>
        </div>
      </Row>
      <Row className="m-5 justify-content-between">
        {hasPrev && (
          <div className="col col-md-8 col-sm-6 col-xs-6">
            <Button disabled={requestStatus === 'loading'} variant="outline-secondary" onClick={() => dispatch(fetchPrevPage())}>Previous</Button>
          </div>
        )}
        {hasNext && (
          <div className="col-2">
            <Button disabled={requestStatus === 'loading'} variant="outline-primary" onClick={() => dispatch(fetchNextPage())}>Next</Button>
          </div>
        )}
      </Row>
    </>
  )
}

export default EventsPage;
