import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { callAPI } from '../../api';
import { signOutUser } from '../profile/profileSlice';

const initialState = {
  events: [],
  status: 'idle',
  cursor: null,
  error: null,
  hasNext: false,
  currentPage: -1,
  pageSize: 10,
  eventType: 'all',
  startDate: null,
  endDate: null,
  deviceId: null,
}

export const fetchNextPage = createAsyncThunk('events/fetchEvents', async (_, thunkAPI) => {
  // Check if we have enough to fetch another page
  const state = thunkAPI.getState().events;
  if ((state.currentPage+1) * state.pageSize < state.events.length) {
    return { newData: false};
  }

  let params = {}
  if (state.cursor !== null) {
    params.next_page = state.cursor;
  } else {
    params.page_size = state.pageSize;
    if (state.eventType !== 'all') {
      params.type = state.eventType;
    }
    params.from = state.startDate;
    params.to = state.endDate;
    params.device = state.deviceId;
  }

  const request = {
    path: '/events',
    params: params
  }
  const response = await callAPI(request, thunkAPI);

  return {
    newData: true,
    ...response
  };
});

export const selectCurrentPage = (state) => {
  const start = state.events.currentPage * state.events.pageSize;
  const end = start + state.events.pageSize;
  return state.events.events.slice(start, end);
}

export const selectHasPrev = (state) => state.events.currentPage > 0;

export const selectFilterParams = (state) => {
  return {
    pageSize: state.events.pageSize,
    eventType: state.events.eventType,
    startDate: state.events.startDate,
    endDate: state.events.endDate,
    deviceId: state.events.deviceId
  }
}

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    fetchPrevPage(state, action) {
      if (state.currentPage > 0)
        state.currentPage -= 1;
    },
    changePageSize(state, action) { // Reset everything to force new events to load
      state.cursor = null;
      state.events = [];
      state.currentPage = -1;
      state.status = 'idle';
      state.pageSize = action.payload;
    },
    changeEventType(state, action) {
      state.cursor = null;
      state.events = [];
      state.currentPage = -1;
      state.status = 'idle';
      state.eventType = action.payload;
    },
    changeStartDate(state, action) {
      state.cursor = null;
      state.events = [];
      state.currentPage = -1;
      state.status = 'idle';
      state.startDate = action.payload;
    },
    changeEndDate(state, action) {
      state.cursor = null;
      state.events = [];
      state.currentPage = -1;
      state.status = 'idle';
      state.endDate = action.payload;
    },
    changeDeviceId(state, action) {
      state.cursor = null;
      state.events = [];
      state.currentPage = -1;
      state.status = 'idle';
      state.deviceId = action.payload;
    },
    clearAllFilters(state) {
      state.cursor = null;
      state.events = [];
      state.currentPage = -1;
      state.status = 'idle';
      state.startDate = null;
      state.endDate = null;
      state.eventType = 'all';
      state.deviceId = null;
    },
  },
  extraReducers: {
    [fetchNextPage.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchNextPage.fulfilled]: (state, action) => {
      state.currentPage += 1;
      state.status = 'succeeded';
      if (action.payload.newData) {
        state.events = state.events.concat(action.payload.events);
        state.cursor = action.payload.page_cursor;
        state.hasNext = action.payload.has_next;
      }
    },
    [signOutUser.fulfilled]: () => {
      return initialState;
    }
  }
});

export const { 
  fetchPrevPage, 
  changePageSize, 
  changeEventType, 
  changeStartDate, 
  changeEndDate, 
  changeDeviceId,
  clearAllFilters
} = eventsSlice.actions;

export default eventsSlice.reducer;
