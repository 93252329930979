import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';


const initialState = {
  error: null
}

const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload.apiError;
    },
    clearApiError(state) {
      state.error = null;
    }
  }
});

export const callAPI = async (request, thunkAPI) => {
  const {
    path,
    api = 'ilabcloud',
    method = 'get',
    body,
    params
  } = request;

  const init = {
    body: body,
    queryStringParameters: params
  }

  try {
    switch (method) {
      case 'get':
        return await API.get(api, path, init);
      case 'post':
        return await API.post(api, path, init);
      case 'put':
        return await API.put(api, path, init);
      case 'delete':
        return await API.del(api, path, init);
      default:
        throw Error('Unknown HTTP Method');
    }
  } catch (err) {
    console.log(err)
    thunkAPI.dispatch(apiSlice.actions.apiError({
      apiError: {
        status: err.response.status,
        data: err.response.data
      }
    }));
    throw err;
  }
}

export const {
  apiError,
  clearApiError
} = apiSlice.actions;

export default apiSlice.reducer;
