import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useEffect } from 'react';
import { clearApiError } from '../api.js'

function PermissionDeniedMessage() {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const apiError = useSelector(state => state.api.error);

  useEffect(() => {
    if (apiError && apiError.status === 403) {
      addToast('Permission denied', { appearance: 'error' });
    } 
    else if (apiError && apiError.status === 404) {
      addToast('Resource does not exist', { appearance: 'error' });
    }
    dispatch(clearApiError); // reset state, so next API error will be displayed
  }, [apiError])

  return null;
}

export default PermissionDeniedMessage;
