import {
  Form,
  Card,
  Col
} from 'react-bootstrap';


import LoadingButton from './LoadingButton';

function NewClientForm(props) {
  return (
    <Col className="col-12 col-xl-6">
      <Card className="bg-light">
        <Card.Body>
          <h4>Add client</h4>
          <Form onSubmit={props.handleSubmit}>
            <Col>
              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control name="name" type="text" placeholder="Name"/>
              </Form.Group>
            </Col>
            <LoadingButton size="lg" isloading={props.loading} type="submit" variant="primary">
              CREATE
            </LoadingButton>
          </Form>

          {props.error !== '' && (<p className="text-danger mt-4">{props.error}</p>)}
        </Card.Body>
      </Card>
    </Col>
  );
}

export default NewClientForm;
