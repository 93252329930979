import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import {
  Form,
  Row,
  Card,
  Col
} from 'react-bootstrap';

import { inviteNewUser } from './usersSlice';
import LoadingButton from '../../components/LoadingButton';

function InviteUserForm() {
  const dispatch = useDispatch();
  const [inviteRequestStatus, setInviteRequestStatus] = useState('idle');
  const [inviteError, setInviteError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inviteRequestStatus === 'idle') {
      setInviteError('');
      try {
        setInviteRequestStatus('pending');
        const formData = new FormData(e.target);
        const email = formData.get('email');
        const name = formData.get('name');

        const result = await dispatch(inviteNewUser({name: name, email: email}));
        unwrapResult(result);
        e.target.reset();
      } catch (err) {
        console.log(err)
        if (err.name === 'api_error') {
          setInviteError(err.message);
        }
      } finally {
        setInviteRequestStatus('idle');
      }
    }
  }

  return (
    <Row>
      <Col className="col-xl-8 col-lg-12 col-md-12">
        <Card className="bg-light">
          <Card.Body>
            <h4>Invite new user</h4>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col className="col-lg-6 col-12">
                  <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control name="name" type="text" placeholder="Name"/>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control name="email" type="email" placeholder="Email"/>
                  </Form.Group>
                </Col>
              </Row>
              <LoadingButton isloading={inviteRequestStatus === 'pending'} type="submit" variant="primary">
                INVITE
              </LoadingButton>
            </Form>
            <Col className="mt-3">
              {inviteError !== '' && (<p className="text-danger">{inviteError}</p>)}
              <p className="text-muted">
                New user will receive an email  with instructions to set their password. 
                After creating a password the user can log in and perform tasks according
                to their role permissions.
              </p>
            </Col>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default InviteUserForm;
