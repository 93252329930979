import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectSearch, {fuzzySearch} from 'react-select-search';
import { useHistory } from 'react-router-dom';

import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button'

import LoadingButton from '../components/LoadingButton';

import { createWebhook } from '../features/webhooks/webhooksSlice';



function EditWebhookPage() {
  const dispatch = useDispatch();
  const devices = useSelector(state => state.devices.devices);

  const history = useHistory();

  const [headers, setHeaders] = useState([]);
  const [device, setDevice] = useState('all');
  const [eventType, setEventType] = useState('all');
  const [isLoading, setIsLoading] = useState(false);

  const deviceOptions = devices.map(d => ({
    name: d.device_id,
    value: d.device_id
  })) 
  deviceOptions.unshift({name: 'All', value: 'all'})

  const eventOptions = [
    {name: 'All', value: 'all'},
    {name: 'Connection status', value:'connection_status_update'},
    {name: 'Lock status', value:'lock_status_update'},
  ]


  const handleHeaderInputChange = (index, event) => {
    const newHeaders = [...headers];
    if (event.target.name === 'header') {
      newHeaders[index].header = event.target.value;
    }
    if (event.target.name === 'value') {
      newHeaders[index].value = event.target.value;
    }
    setHeaders(newHeaders)
  }

  const handleHeaderDelete = (index) => {
    const newHeaders = [...headers];
    newHeaders.splice(index, 1);
    setHeaders(newHeaders);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    const url = formData.get('url');
    const formattedHeaders = headers.length > 0 ? headers.reduce((a, c) => ({...a, [c.header]: c.value})) : null;

    setIsLoading(true);

    let hookData = {
      url: url,
    }

    if (device !== 'all')
      hookData.deviceId = device;
    if (eventType !== 'all')
      hookData.eventType = eventType;
    if (formattedHeaders !== null)
      hookData.headers = formattedHeaders;

    dispatch(createWebhook(hookData));
    setIsLoading(false);
    history.goBack();
  }

  return (
    <div className="m-5" style={{maxWidth: "480px"}}>
      <Card className="p-5 bg-light">
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Callback URL</Form.Label>
            <Form.Control name="url" type="url" required placeholder="https://..."></Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Device</Form.Label>
            <SelectSearch
              name="device"
              options={deviceOptions}
              filterOptions={fuzzySearch}
              search={true}
              placeholder="Select a device"
              value={device}
              onChange={setDevice}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Event type</Form.Label>
            <SelectSearch options={eventOptions} value={eventType} onChange={setEventType}/>
          </Form.Group>
          <Form.Group>
            <Form.Label>Headers</Form.Label>
            {headers.map((header, index) => (
              <Row key={index} className="mb-2">
                <Col lg={5}>
                  <Form.Control 
                    name="header"
                    required
                    onChange={(e) => handleHeaderInputChange(index, e)}
                    placeholder="header.." 
                    value={header.header}
                  />
                </Col>
                <Col lg={5}>
                  <Form.Control
                    name="value"
                    required
                    onChange={(e) => handleHeaderInputChange(index, e)}
                    placeholder="value.."
                    value={header.value}
                  />
                </Col>
                <Col lg={2}>
                  <Button
                    variant="outline-danger"
                    onClick={() => handleHeaderDelete(index)}
                  >X</Button>
                </Col>
              </Row>
            ))}
            <Row className="mt-2">
              <Col>
                <Button 
                  variant="outline-primary" 
                  onClick={() => setHeaders(headers.concat({header:'', value: ''}))}
                >
                  ADD HEADER
                </Button>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col className="col-6">
                <LoadingButton 
                  size="lg"
                  variant="primary" 
                  type="submit"
                  isloading={isLoading}
                >
                  CREATE
                </LoadingButton>
              </Col>
              <Col>
                <Button 
                  size="lg"
                  variant="outline-secondary" 
                  onClick={() => history.goBack()}
                >
                  BACK
                </Button>
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </Card>
    </div>
  )
}

export default EditWebhookPage;
