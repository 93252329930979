import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import LoadingButton from '../components/LoadingButton'
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { fetchDevices } from '../features/devices/devicesSlice';
import { useDispatch } from 'react-redux';

import { API } from 'aws-amplify';


function ClaimDevicePage() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [deviceId, setDeviceId] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setMessage("");
    API.post('ilabcloud', `/devices/${deviceId}/claim`)
      .then(response => {
        if (response.claim_status === 'pending') {
          setMessage(response.info);
          checkClaimStatus(deviceId)
        } else {
          setMessage(response.status)
          setLoading(false);
          setError(true);
        }
      })
      .catch(error => {
        setMessage(error.response.data.status);
        setLoading(false);
        setError(true);
      });
  }

  const checkClaimStatus = async (deviceId) => {
    try {
      const response = await API.get('ilabcloud', `/devices/${deviceId}/claim/status`);
      if (response.claim_status === 'pending') {
        setTimeout(checkClaimStatus, 2000, deviceId);
      } else if (response.claim_status === 'claimed') {
        dispatch(fetchDevices())
        history.push(`/devices/${deviceId}`)
      } else if (response.claim_status === 'verification_failed') {
        setMessage('Device verification timed out')
        setLoading(false);
      } 
    } catch (e) {
      setMessage(e.response.data.status)
      setLoading(false)
      setError(true);
    }
  }


  return (
    <div className="m-5" style={{maxWidth: "800px"}}>
      <Card className="p-5 bg-light">
        <h2>Claim Device</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Text>
            Enter the device ID to start the claim process and add a new device to your organization
          </Form.Text>
          <Form.Group>
            <Form.Control name="device" type="text" value={deviceId} onChange={(e) => setDeviceId(e.target.value)}placeholder="Enter device ID"></Form.Control>
          </Form.Group>
          <Form.Group>
            <LoadingButton size="lg" variant="primary" isloading={loading} type="submit">
              CLAIM
            </LoadingButton>
          </Form.Group>
        </Form>
        <p className={error ? 'text-danger' : 'text-muted'}>{message}</p>
      </Card>
    </div>
  )
}

export default ClaimDevicePage;
