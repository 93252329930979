import Table from 'react-bootstrap/Table';
import { DateTime } from 'luxon';

import Spinner from 'react-bootstrap/Spinner';

function EventData(props) {
  if (props.event_type === 'connection_status_update') {
    return props.data.connection_status;
  } else if (props.event_type === 'lock_status_update') {
    return props.data.lock_status;
  }
  return 'error';
}

function EventType(props) {
  if (props.event_type === 'connection_status_update') {
    return 'Connection status';
  }
  if (props.event_type === 'lock_status_update') {
    return 'Lock status';
  }
  return 'error';
}


function EventsTable(props) {
  const events = props.events;

  const showEvents = (
    events.length > 0 ? (events.map((e) => (
      <tr key={e.event_id} className="d-flex">
        <td className="col">{e.device_id}</td>
        <td className="col"><EventType {...e}/></td>
        <td className="col"><EventData {...e}/></td>
        <td className="col">{DateTime.fromISO(e.timestamp).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}</td>
      </tr>
    ))) : (
      <div className="mt-3 col text-center">
        <p>No events to display!</p>
      </div>
    )
  )

  const loadingSpinner = (
    <div className="col text-center">
      <Spinner className="mx-auto my-5" animation="border"/>
    </div>
  );

  return (
    <div className="d-flex table-border w-100 bg-light">
      <Table className="mb-0">
        <thead>
          <tr className="d-flex">
            <th className="col">DEVICE</th>
            <th className="col">EVENT TYPE</th>
            <th className="col">INFO</th>
            <th className="col">TIMESTAMP</th>
          </tr>
        </thead>
        <tbody>
          {props.loading ? loadingSpinner : showEvents}
        </tbody>
      </Table>
    </div>
  );
}

export default EventsTable;
