import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import {
  selectClient,
  fetchClient,
  addPermission,
  deleteClient,
  deletePermission,
} from '../features/clients/clientsSlice';


function SingleClientPage() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [ displaySecret, setDisplaySecret ] = useState(false);
  const { clientId } = useParams();

  const client = useSelector((state) => selectClient(state, clientId));

  const allPermissions = [
    {"label": "Device read", "value": "device_read"},
    {"label": "Device write", "value": "device_write"},
    {"label": "Device claim", "value": "device_claim"},
    {"label": "Device declaim", "value": "device_declaim"},
    {"label": "Event read", "value": "event_read"},
    {"label": "Client read", "value": "client_read"},
    {"label": "Client write", "value": "client_edit"},
    {"label": "Webhook read", "value": "webhook_read"},
    {"label": "Webhook edit", "value": "webhook_edit"},
    {"label": "Permission read", "value": "permission_read"},
    {"label": "Permission edit", "value": "permission_edit"},
    {"label": "User read", "value": "user_read"},
    {"label": "User edit", "value": "user_edit"}
  ]

  useEffect(() => {
    if (client === null || !client.hasOwnProperty('permissions')) {
      dispatch(fetchClient(clientId));
    }
  }, []);

  function onDeleteClient() {
    dispatch(deleteClient(clientId));
    history.goBack();
  }

  function removePermission(permission) {
    dispatch(deletePermission({clientId: clientId, permission: permission}));
  }

  return (
    <>
      <Row>
        <Col className="col-12 col-lg-8 col-xl-7">
          <Card className="ml-5 bg-light p-3">
            {client === null ? (
              <Spinner animation="border" className="mb-4 mx-auto"/>
            ) : (
              <>
                <h3 className="mb-4">{client.name}</h3>
                <Row>
                  <Col>
                    <p className="mb-0 text-muted font-size-small">Client ID</p>
                    <p className="text-monospace">{client.client}</p>
                  </Col>
                  {displaySecret && (
                    <Col>
                      <p className="mb-0 text-muted font-size-small">Secret</p>
                      <p className="text-monospace">{client.secret}</p>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col className="col-auto">
                    <Button 
                      onClick={() => setDisplaySecret(!displaySecret)}
                      variant="outline-secondary"
                    >
                      {displaySecret ? "HIDE SECRET" : "SHOW SECRET"}
                    </Button>
                  </Col>
                  <Col className="col-3">
                  <Button 
                      onClick={onDeleteClient}
                      variant="outline-danger"
                    >
                      DELETE
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className="col-12 col-lg-8 col-xl-7">
          {client !== null && client.permissions && (
            <Card className="ml-5 mt-4 bg-light">
              <Card.Body>
                <h4>Permissions</h4>
                <hr/>
                <Form>
                  {allPermissions.map(({label, value}) => (
                    <Row>
                      <Col>
                        <p>{label}</p>
                      </Col>
                      <Col>
                        <Form.Check 
                          id={value} 
                          type="switch" 
                          checked={client.permissions.includes(value)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              dispatch(addPermission({clientId: client.id, permission: value}))
                            } else {
                              removePermission(value)
                            }
                          }}
                        />
                      </Col>
                    </Row>
                  ))}
                </Form>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </>
  )
}

export default SingleClientPage;
