import Form from 'react-bootstrap/Form';
import { Link, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import LoadingButton from '../components/LoadingButton';

import { signInUser } from '../features/profile/profileSlice';

// Implements a simple form used to log users in
function SignInPage() {
  
  const isLoading = useSelector(state => state.profile.status === 'loading');

  const signInError = useSelector(state => state.profile.error);
  const isSignedIn = useSelector(state => state.profile.signedIn);

  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    if (isSignedIn)
      history.push("/");
  }, [isSignedIn]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    let username = formData.get('username');
    let password = formData.get('password');
    dispatch(signInUser({username, password}))
  }

  return (
    <div className="card p-4 form-max-width">
      <h3>Sign in</h3>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="username">
          <Form.Label>Email address</Form.Label>
          <Form.Control name="username" type="email" placeholder="enter email"/>
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control name="password" type="password" placeholder="enter password"/>
          {signInError !== "" && 
            <Form.Text className="text-danger">{signInError}</Form.Text>
          }
        </Form.Group>
        <Row className="justify-content-between">
          <Col>
            <LoadingButton variant="primary" isloading={isLoading} type="submit">
              SIGN IN
            </LoadingButton>
          </Col>
          <Col className="text-right">
            <Link to="/reset-password">Forgot password?</Link>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default SignInPage;
