import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Auth } from 'aws-amplify';
import { useHistory, useLocation } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import LoadingButton from '../components/LoadingButton';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ConfirmPasswordResetPage() {
  const history = useHistory();
  const query = useQuery();

  const [loading, setLoading] = useState(false);
  const [hasReset, setHasReset] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(e.target);
    const password = formData.get('password')
    const code = formData.get('code')
    Auth.forgotPasswordSubmit(query.get('username'), code, password)
      .then(() => {
        setHasReset(true);
        setLoading(false);
      });
  }

  console.log(query)

  return (
    <div className="card p-4 form-max-width">
      <h3>Confirm password reset</h3>
      {!hasReset && (
        <Form onSubmit={onSubmit}>
          <Form.Group controlId="code">
            <Form.Label>Confirmation code</Form.Label>
            <Form.Control required name="code" placeholder="enter code"/>
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>New password</Form.Label>
            <Form.Control required name="password" type="password" placeholder="password"/>
          </Form.Group>
          <LoadingButton isloading={loading} size="lg" type="submit">SET PASSWORD</LoadingButton>
        </Form>
      )}
      {hasReset && (
        <>
          <p>Password has been saved. Login to continue.</p>
          <Button variant="primary" onClick={() => history.push('/login')}>LOGIN</Button>
        </>
      )}
    </div>
  )
}

export default ConfirmPasswordResetPage;
