import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { AWS_CONFIG } from './configs/aws';

import Amplify, { Auth } from 'aws-amplify';

import {
  Switch,
  Route,
  Link,
  NavLink
} from 'react-router-dom';

import { ToastProvider } from 'react-toast-notifications';
import {Container} from 'react-bootstrap';

import DevicesPage from './pages/DevicesPage';
import EventsPage from './pages/EventsPage';
import UsersPage from './pages/UsersPage';
import ClientsPage from './pages/ClientsPage';
import SignInPage from './pages/SignInPage';
import ClaimDevicePage from './pages/ClaimDevicePage';
import WebhooksPage from './pages/WebhooksPage';
import HomePage from './pages/HomePage';
import OrganizationPage from './pages/OrganizationPage';
import CustomToastNotification from './components/CustomToastNotification';
import { userAlreadySignedIn, signOutUser, loadUserAttributes, newCognitoUser } from './features/profile/profileSlice';
import PermissionDeniedMessage from './components/PermissionDeniedMessage';
import ShowForRole from './components/ShowForRole';
import ProfileDropdown from './components/ProfileDropdown';
import AuthRoute from './components/AuthRoute';

import logo from './logo.svg';
import './css/ilab-custom-bootstrap.scss';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ConfirmPasswordResetPage from './pages/ConfirmPasswordResetPage';
import ChangePasswordPage from './pages/ChangePasswordPage';

Amplify.configure(AWS_CONFIG);

function App() {
  const dispatch = useDispatch();

  // Assume there is a session so that authed users aren't sent to login
  // before the logged in check has happened. Unauthenticated users are
  // redirected as soon as check has finished.
  const [hasSession, setHasSession] = useState(true); 

  const isAuthenticated = useSelector(state => state.profile.signedIn);
  const user = useSelector(state => state.profile.user);
  const cognitoUser = useSelector(state => state.profile.cognitoUser);
  const profileRequestState = useSelector(state => state.profile.status);

  const history = useHistory();

  const [showMenu, setShowMenu] = useState(false);

  async function checkLoggedIn() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      dispatch(userAlreadySignedIn());
      dispatch(newCognitoUser(user));
      setHasSession(true);
    } catch {
      setHasSession(false);
    }
  }

  useEffect(() => {
    checkLoggedIn();
  }, [])

  useEffect(() => {
    // if user is authenticated but attributes haven't been requested yet
    if (isAuthenticated && user === null && profileRequestState !== 'loading') { 
      dispatch(loadUserAttributes());
    }
  }, [user, isAuthenticated, profileRequestState]);

  useEffect(() => {
    if (cognitoUser && cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED')
      history.push('/set-first-password')
  }, [cognitoUser])

  const handleSignOut = async () => {
    try {
      dispatch(signOutUser());
      history.push("/login");
    } catch (err) {
      console.log("failed to log out", err);
    }
  };

  return (
    <div className="App">
      <ToastProvider autoDismiss components={{Toast: CustomToastNotification}} placement="bottom-right">
        <PermissionDeniedMessage />
        <div className={"row-offcanvas row-offcanvas-left" + (showMenu ? " active" : "")}>
          <div id="sidebar" className="sidebar-offcanvas bg-light">
            <div className="list-group">
              <Nav.Item className="px-2">
                <Link to="/"><Image id="brand-logo" className="" src={logo}/></Link>
              </Nav.Item>
              {isAuthenticated ? (
                <>
                  <Nav.Item className="mt-2 ml-4">
                    <NavLink to="/devices" className="nav-link" activeClassName="active">
                      Devices
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item className="mt-2 ml-4">
                    <NavLink to="/events" className="nav-link" activeClassName="active">
                      Events
                    </NavLink>
                  </Nav.Item>
                  <ShowForRole developer admin>
                    <Nav.Item className="mt-2 ml-4">
                      <NavLink to="/clients" className="nav-link" activeClassName="active">
                        App clients
                      </NavLink>
                    </Nav.Item>
                  </ShowForRole>
                  <ShowForRole developer admin readonly>
                    <Nav.Item className="mt-2 ml-4">
                      <NavLink to="/users" className="nav-link" activeClassName="active">
                        Users
                      </NavLink>
                    </Nav.Item>
                    <Nav.Item className="mt-2 ml-4">
                      <NavLink to="/webhooks" className="nav-link" activeClassName="active">
                        Webhooks
                      </NavLink>
                    </Nav.Item>
                  </ShowForRole>
                  <div className="mx-2 px-3">
                    <hr/>
                    <a href="http://docs.industrilas.cloud"><h5>API Docs</h5></a>
                    <a href="https://industrilas.com/en-us/contact-us/worldwide"><h5>Contact</h5></a>
                  </div>
                </>
              ) : (
                <p>Please sign in to use the Industrilas Cloud</p>
              )}
            </div>
          </div>
          <div id="main">
          <Container fluid>
            <div className="row">
              <div className="col-md-12">
                <div className="row no-gutters align-items-center justify-content-between">
                  <div className="col-3 pt-4">
                    <p className="d-block d-md-none">
                      <Button variant="primary" onClick={() => setShowMenu(!showMenu)}>Menu</Button>
                    </p>
                  </div>
                  <div className="col-5 my-3 px-3 pt-4 text-right">
                    {isAuthenticated ? (
                        <ProfileDropdown handleSignOut={handleSignOut}/>
                      ) : (
                        <Button variant="primary" size="lg" onClick={() => history.push('/login')}>
                          Sign In
                        </Button>
                      )
                    }
                  </div>
                </div>
                <hr/>
                <Switch>
                  <AuthRoute authenticated={hasSession} exact path="/">
                    <HomePage />
                  </AuthRoute>
                  <Route path="/login">
                    <Col>
                      <div className="d-flex justify-content-center">
                        <SignInPage />
                      </div>
                    </Col>
                  </Route>
                  <Route path="/reset-password">
                    <Col className="d-flex justify-content-center">
                      <ResetPasswordPage />
                    </Col>
                  </Route>
                  <Route path="/confirm-password-reset">
                    <Col className="d-flex justify-content-center">
                      <ConfirmPasswordResetPage />
                    </Col>
                  </Route>
                  <Route path="/change-password">
                    <Col className="d-flex justify-content-center">
                      <ChangePasswordPage />
                    </Col>
                  </Route>
                  <Route path="/set-first-password">
                    <Col className="d-flex justify-content-center">
                      <ChangePasswordPage firstTimePassword />
                    </Col>
                  </Route>
                  <AuthRoute authenticated={hasSession} path="/devices">
                    <DevicesPage/>
                  </AuthRoute>
                  <AuthRoute authenticated={hasSession} path="/events">
                    <EventsPage/>
                  </AuthRoute>
                  <AuthRoute authenticated={hasSession} path="/users">
                    <UsersPage/>
                  </AuthRoute>
                  <AuthRoute authenticated={hasSession} path="/clients">
                    <ClientsPage/>
                  </AuthRoute>
                  <AuthRoute authenticated={hasSession} path="/webhooks">
                    <WebhooksPage />
                  </AuthRoute>
                  <AuthRoute authenticated={hasSession} path="/organization">
                    <OrganizationPage/>
                  </AuthRoute>
                  <AuthRoute authenticated={hasSession} path="/claim">
                    <ClaimDevicePage/>
                  </AuthRoute>
                </Switch>
              </div>
            </div>
          </Container>
          </div>
        </div>
      </ToastProvider>
    </div>
  );
}

export default App;
