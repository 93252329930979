import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import { useEffect, useState } from 'react';
import { useHistory, Switch, Route, useRouteMatch} from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import {
  fetchClients,
  createClient,
  selectAllClients,
} from '../features/clients/clientsSlice';

import NewClientForm from '../components/NewClientForm';
import SingleClientPage from './SingleClientPage';

function ClientsPage() {
  const dispatch = useDispatch();
  const appClients = useSelector(selectAllClients);
  const requestStatus = useSelector(state => state.clients.status);

  const [ loading, setLoading ] = useState(false);
  const [ errorText, setErrorText ] = useState("");

  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    if (requestStatus === 'idle') {
      dispatch(fetchClients());
    }
  }, []);

  const handleNewClient = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(e.target);
    try {
      const result = await dispatch(createClient(formData.get('name')));
      unwrapResult(result);
    } catch (err) {
      console.log(err)
      if (err.message.includes('409')) {
        setErrorText("Too many app clients (max: 5). Please remove a client to create a new one."); // little getto
      }
    }
    setLoading(false);
  };

  const onShowClient = (id) => {
    history.push(`${match.url}/${id}`);
  }

  return (
    <Switch>
      <Route path={`${match.path}/:clientId`}>
        <SingleClientPage />
      </Route>
      <Route path={match.path}>
        <Row className="m-5">
          <div className="table-border w-100 bg-light">
            <Table hover className="mb-0">
              <thead>
                <tr className="">
                  <th className="col">NAME</th>
                  <th className="col">CLIENT ID</th>
                </tr>
              </thead>
              {requestStatus === 'loading' ? (
                <div className="d-flex w-100 align-items-center">
                  <Spinner className="mx-auto my-5" animation="border"/>
                </div>
                ) : (
                <tbody>
                  {Object.values(appClients).map((c) => 
                    <tr 
                      className="clickable-row" 
                      key={c.id}
                      onClick={() => onShowClient(c.id)}
                    >
                      <td className="col font-weight-light">{c.name}</td>
                      <td className="col text-monospace font-weight-light">{c.client}</td>
                    </tr>
                  )
                  }
                </tbody>
              )}
            </Table>
          </div>
        </Row>
        <Row className="m-5">
          <NewClientForm error={errorText} loading={loading} handleSubmit={handleNewClient}/>
        </Row>
      </Route>
    </Switch>
  )
}

export default ClientsPage;
