import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';

function ProfileDropdown({handleSignOut}) {
  const user = useSelector(state => state.profile.user);
  const org = useSelector(state => state.profile.org);
  const history = useHistory();

  return (
    <Dropdown >
      <Dropdown.Toggle variant="secondary">
        Profile
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {org && user && (
          <>
            <Dropdown.Header>Profile</Dropdown.Header>
            <Dropdown.Item as="button" onClick={() => history.push('/organization')}>{org.org_name}</Dropdown.Item>
            <Dropdown.ItemText>{user.name}</Dropdown.ItemText>
            <Dropdown.ItemText><i>{user.role}</i></Dropdown.ItemText>
          </>
        )}
        <Dropdown.Divider />
        <Dropdown.Item as="button" onClick={() => history.push('/change-password')}>Change password</Dropdown.Item>
        <Dropdown.Item as="button" onClick={() => handleSignOut()}>Sign out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ProfileDropdown;
