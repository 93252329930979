import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';

import Dropdown from 'react-bootstrap/Dropdown';
import Spinner from 'react-bootstrap/Spinner';

import { updateUserRole } from './usersSlice';

function RoleDropdown(props) {
  const dispatch = useDispatch();
  const user = props.user;

  const [roleRequestStatus, setRoleRequestStatus] = useState('idle');

  const onSetNewRole = async (newRole) => {
    if (roleRequestStatus === 'idle') {
      try {
        const updatedUser = {
          ...user,
          newRole: newRole
        }

        setRoleRequestStatus('pending');
        const result = await dispatch(updateUserRole(updatedUser));
        unwrapResult(result); 
      } catch (err) {
        console.error(err);
      } finally {
        setRoleRequestStatus('idle');
      }
    }
  }

  const title = roleRequestStatus === 'idle' ? 
    (user.role.charAt(0).toUpperCase() + user.role.slice(1)) :
    (<Spinner animation="border" size="sm"/>);

  return (
    <Dropdown> 
      <Dropdown.Toggle className="w-100" variant="secondary">
        {title}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => onSetNewRole('admin')}>Admin</Dropdown.Item>
        <Dropdown.Item onClick={() => onSetNewRole('developer')}>Developer</Dropdown.Item>
        <Dropdown.Item onClick={() => onSetNewRole('installer')}>Installer</Dropdown.Item>
        <Dropdown.Item onClick={() => onSetNewRole('read_only')}>Read only</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default RoleDropdown;
