import { configureStore } from '@reduxjs/toolkit';

import usersReducer from '../features/users/usersSlice';
import eventsReducer from '../features/events/eventsSlice';
import devicesReducer from '../features/devices/devicesSlice';
import clientsReducer from '../features/clients/clientsSlice';
import profileReducer from '../features/profile/profileSlice';
import webhooksReducer from '../features/webhooks/webhooksSlice';
import apiReducer from '../api';

export default configureStore({
  reducer: {
    users: usersReducer,
    events: eventsReducer,
    devices: devicesReducer,
    clients: clientsReducer,
    profile: profileReducer,
    api: apiReducer,
    webhooks: webhooksReducer,
  }
});
