import { Auth } from 'aws-amplify';

export const AWS_CONFIG = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: "ilabcloud",
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        custom_header: async() => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
          }
        }
      }
    ]
  }
}
