import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

const LoadingButton = (props) => {
  const invisible = {
    opacity: 0
  };

  const spinnerStyle = {
    position: 'absolute',
    top: 'calc(50% - 1rem)',
    left: 'calc(50% - 1rem)'
  }

  return (
    <Button
      {...props}
      style={{position: 'relative'}}
      onClick={props.onClick}
      disabled={props.isloading}
    >
      <span style={props.isloading ? invisible : {}}>
        {props.children}
      </span>
      <span style={spinnerStyle}>
        {props.isloading &&
          <Spinner 
            as="span"
            animation="border"
            role="status"
          />
        }
      </span>
    </Button>
  )
};

export default LoadingButton;
