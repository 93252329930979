import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { newCognitoUser } from '../features/profile/profileSlice';
import LoadingButton from '../components/LoadingButton';

function ChangePasswordPage({firstTimePassword}) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const cognitoUser = useSelector(state => state.profile.cognitoUser);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(e.target);
    const password = formData.get('password');
    if (firstTimePassword) {
      Auth.completeNewPassword(cognitoUser, password)
        .then(user => {
          dispatch(newCognitoUser(user));
          history.push('/');
        }).catch(err => {
          setError(err.message);
          setLoading(false);
        });
    } else {
      const oldPassword = formData.get('old');
      Auth.changePassword(cognitoUser, oldPassword, password).then(() => 
        history.goBack()
      ).catch(err => {
        setError(err.message);
        setLoading(false);
      });
    }
  }

  return (
    <div className="card p-4 form-max-width">
      <h3>Reset password</h3>
      <Form onSubmit={onSubmit}>
        <Form.Group controlId="old">
          <Form.Label>Current Password</Form.Label>
          <Form.Control required name="old" type="password" placeholder="enter password"/>
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>New password</Form.Label>
          <Form.Control required name="password" type="password" placeholder="new password"/>
          <Form.Text className="text-muted">
            Enter a new password.
          </Form.Text>
          {error !== "" && (
            <Form.Text className="text-danger">
              {error}
            </Form.Text>
          )}
        </Form.Group>
        <LoadingButton isloading={loading} size="lg" type="submit">SAVE</LoadingButton>
      </Form>
    </div>
  );
}

export default ChangePasswordPage;
