import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Auth, API} from 'aws-amplify';
import { callAPI } from '../../api';

const initialState = {
  user: null,
  org: null,
  cognitoUser: null,
  signedIn: false,
  error: "",
  status: 'idle',
  org_status: 'idle'
}

export const signInUser = createAsyncThunk('profile/signInUser', async (arg) => {
  return await Auth.signIn(arg.username, arg.password);
})

export const signOutUser = createAsyncThunk('profile/signOutUser', async () => {
  return await Auth.signOut();
})

export const loadUserAttributes = createAsyncThunk('profile/loadUserAttributes', async () => {
  const { attributes } = await Auth.currentAuthenticatedUser(); 
  // TODO: Get these requests in parallel
  const org = await API.get('ilabcloud', '/organization');
  const response = await API.get('ilabcloud', '/users');
  const user = response.users.find(u => u.email === attributes.email);
  return { user, org };
});

export const updateOrgData = createAsyncThunk('profile/updateOrgData', async (arg, thunkAPI) => {
  const request = {
    path: '/organization',
    method: 'put',
    body: arg.org
  }
  const response = await callAPI(request, thunkAPI);
  return response;
});

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    userAlreadySignedIn: (state) => {
      state.signedIn = true;
    },
    newCognitoUser: (state, action) => {
      state.cognitoUser = action.payload
    }
  },
  extraReducers: {
    [signInUser.pending]: (state) => {
      state.status = 'loading';
    },
    [signInUser.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [signInUser.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.signedIn = true;
      state.error = "";
      state.cognitoUser = action.payload;
    },
    [signOutUser.pending]: (state) => {
      state.status = 'loading';
    },
    [signOutUser.fulfilled]: (state) => {
      state.user = null;
      state.cognitoUser = null;
      state.status = 'idle';
      state.signedIn = false;
      state.org = null;
    },
    [loadUserAttributes.pending]: (state) => {
      state.status = 'loading';
    },
    [loadUserAttributes.rejected]: (state) => {
      state.status = 'failed';
    },
    [loadUserAttributes.fulfilled]: (state, action) => {
      state.user = action.payload.user;
      state.org = action.payload.org;
      state.status = 'succeeded';
    },
    [updateOrgData.pending]: (state) => {
      state.org_status = 'loading';
    },
    [updateOrgData.fulfilled]: (state, action) => {
      state.org = action.payload
      state.org_status = 'succeeded'
    }
  }
});

export const { userAlreadySignedIn, newCognitoUser } = profileSlice.actions;

export default profileSlice.reducer;
