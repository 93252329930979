import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { callAPI } from '../../api';

import { signOutUser } from '../profile/profileSlice';

const initialState = {
  clients: {},
  status: 'idle',
  error: null
}

export const fetchClients = createAsyncThunk('clients/fetchClients', async(_, thunkAPI) => {
  const request = {
    path: '/clients'
  }
  const response = await callAPI(request, thunkAPI);
  return response.clients;
});

// Fetches client and all permissions
export const fetchClient = createAsyncThunk('clients/fetchClient', async (id, thunkAPI) => {
  const clientReq = {
    path: `/clients/${id}`
  }
  const clientResponse = await callAPI(clientReq, thunkAPI);
  const permissionsReq = {
    path: `/clients/${id}/permissions`
  }
  const permissionsResponse = await callAPI(permissionsReq, thunkAPI);
  return {
    client: clientResponse,
    permissions: permissionsResponse.permissions
  };
});

export const createClient = createAsyncThunk('clients/createClient', async (name, thunkAPI) => {
  const request = {
    method: 'post',
    path: '/clients',
    body: {
      name: name
    }
  }
  const response = await callAPI(request, thunkAPI);
  return response;
});

export const deleteClient = createAsyncThunk('clients/deleteClient', async (id, thunkAPI) => {
  const request = {
    method: 'delete',
    path: `/clients/${id}`
  }
  await callAPI(request, thunkAPI);
  return id;
});

export const addPermission = createAsyncThunk('clients/addPermission', async (data, thunkAPI) => {
  const request = {
    method: 'post',
    path: `/clients/${data.clientId}/permissions`,
    body: {
      permission: data.permission
    }
  };
  const response = await callAPI(request, thunkAPI);
  return {
    clientId: data.clientId,
    permissions: response.permissions
  }
});

export const deletePermission = createAsyncThunk('clients/deletePermission', async (data, thunkAPI) => {
  const request = {
    method: 'delete',
    path: `/clients/${data.clientId}/permissions/${data.permission}`,
  }
  await callAPI(request, thunkAPI);
  return data;
});

const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchClients.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchClients.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      action.payload.forEach((client) => {
        state.clients[client.id] = client;
      });
    },
    [fetchClient.fulfilled]: (state, action) => {
      const client = action.payload.client;
      state.clients[client.id] = client;
      state.clients[client.id].permissions = action.payload.permissions;
    },
    [createClient.fulfilled]: (state, action) => {
      state.clients[action.payload.id] = action.payload;
    },
    [deleteClient.fulfilled]: (state, action) => {
      delete state.clients[action.payload];
    },
    [addPermission.fulfilled]: (state, action) => {
      state.clients[action.payload.clientId].permissions = action.payload.permissions;
    },
    [deletePermission.fulfilled]: (state, action) => {
      const oldPermissions = state.clients[action.payload.clientId].permissions;
      state.clients[action.payload.clientId].permissions = oldPermissions.filter((permission) => {
        return permission !== action.payload.permission;
      })
    },
    [signOutUser.fulfilled]: () => {
      return initialState;
    }
  }
});

export default clientsSlice.reducer;

export const selectClient = (state, clientId) => {
  const c = state.clients.clients[clientId];
  if (c === undefined) 
    return null;
  return c
};

export const selectAllClients = (state) => state.clients.clients;
