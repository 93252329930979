import { useSelector } from 'react-redux';

function ShowForRole(props) {
  const user = useSelector(state => state.profile.user)

  if (!user) {
    return null;
  }

  //const show = (props.roles.includes(user.role) || props.role === user.role);
  const show = (
    (props.admin && user.role === 'admin') ||
    (props.developer && user.role === 'developer') ||
    (props.installer && user.role === 'installer') ||
    (props.readonly && user.role === 'read_only')
  );

  return show ? props.children : null;
}

export default ShowForRole;
