import Alert from 'react-bootstrap/Alert';

const CustomToastNotification = ({appearance, onDismiss, children}) => {
  const variants = {
    success: 'success',
    error: 'danger',
    warning: 'warning',
    info: 'info'
  }

  return (
    <Alert className="mr-5" variant={variants[appearance]} style={{minWidth: "15rem"}} onDismiss={onDismiss}>
      <Alert.Heading>Notice</Alert.Heading>
      {children}
    </Alert>
  );
}

export default CustomToastNotification;
