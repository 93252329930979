import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { callAPI } from '../../api';
import { signOutUser } from '../profile/profileSlice';

const initialState = {
  users: [],
  status: 'idle',
  error: null
};

export const fetchUsers = createAsyncThunk('users/fetchUsers', async (_, thunkAPI) => {
  const request = {
    path: '/users'
  }
  const response = await callAPI(request, thunkAPI)
  return response.users;
});

export const updateUserRole = createAsyncThunk('users/updateRole', async (user, thunkAPI) => {
  const request = {
    path: `/users/${user.user_id}`,
    method: 'put',
    body: {
      role: user.newRole
    }
  }
  const response = await callAPI(request, thunkAPI);
  return response;
});

export const inviteNewUser = createAsyncThunk('users/inviteNewUser', async (user) => {
  try {
    const response = await API.post('ilabcloud', '/users/register', { body: {
      name: user.name,
      email: user.email
    }});
    return response;
  } catch (err) {
    if (err.response.data) {
      console.log(err.response.data);
      throw {
        name: 'api_error',
        message: err.response.data.message
      };
    } else {
      throw err;
    }
  }
});

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUsers.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchUsers.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.users = state.users.concat(action.payload);
    },
    [fetchUsers.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [updateUserRole.fulfilled]: (state, action) => {
      const updatedUser = action.payload;
      state.users.find(user => user.user_id === updatedUser.user_id).role = updatedUser.role;
    },
    [inviteNewUser.fulfilled]: (state, action) => {
      state.users.push(action.payload);
    },
    [signOutUser.fulfilled]: () => {
      return initialState;
    }
  }
});


export default usersSlice.reducer;
