import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import { CircleFill } from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useDispatch } from 'react-redux';
import { fetchDevices } from '../features/devices/devicesSlice';

import { API } from 'aws-amplify';

import LoadingButton from '../components/LoadingButton';
import ShowForRole from '../components/ShowForRole';

function SingleDevicePage() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { addToast } = useToasts();

  const { deviceId } = useParams();
  const [device, setDevice] = useState({});
  const [unlockIsLoading, setUnlockIsLoading] = useState(false);

  useEffect(() => {
    async function getDevice() {
      const newDevice = await API.get('ilabcloud', `/devices/${deviceId}`);
      setDevice(newDevice);
    }
    getDevice();
  }, []);

  function unclaimDevice() {
    API.post('ilabcloud', `/devices/${device.device_id}/unclaim`)
      .then(response => {
        console.log(response);
        if (response.status === 'unclaim_successful') {
          dispatch(fetchDevices())
          history.push('/devices');
        }
        else {
          console.log("failed to unclaim device");
        }
          // add error here
      }).catch(error => {
        console.log(error.response);
      });
  }

  function unlockDevice() {
    setUnlockIsLoading(true);
    API.post('ilabcloud', `/devices/${device.device_id}/unlock`)
      .then(response => {
        if (response.status !== 'unlock_success') {
          console.error("Failed to unlock: ", response);
          addToast("Unlock failed", { appearance: 'error' });
        } else {
          addToast("Unlock succeeded", { appearance: 'success' });
        }
        setUnlockIsLoading(false);
      }).catch(error => {
        console.log(error.response);
        setUnlockIsLoading(false);
      });
  }

  let last_online = 'unknown';
  if (device.hasOwnProperty('last_online')) {
    last_online = new Date(device.last_online).toLocaleString();
  }

  return (
    <Row>
      <Col className="col-lg-8 col-md-12">
        <Card className="m-3 bg-light p-3">
          {!device.hasOwnProperty('device_id') ? (<Spinner variant="secondary" className="mb-4" animation="border" role="status"/>) : (
            <h3 className="mb-4">{device.device_id}</h3>
          )}
          <Row>
            <Col>
              <h5>VMAX Device</h5>
              <p>Lock status: {device.lock_status}</p>
            </Col>
            <Col>
              <CircleFill color={device.connection_status==='online' ? "green" : "red"} className="mr-2"/>
              {device.connection_status}
              <p className="text-muted font-italic"><small>Last heard: {last_online}</small></p>
            </Col>
          </Row>
          <ShowForRole admin developer installer>
            <Row>
              <Col className="mt-5">
                <LoadingButton 
                  className="mr-4" 
                  size="lg"
                  onClick={unlockDevice}
                  isloading={unlockIsLoading}
                >
                  UNLOCK
                </LoadingButton>
                <Button 
                  className="mr-4"
                  variant="outline-danger" 
                  size="lg"
                  onClick={unclaimDevice}
                >
                  UNCLAIM
                </Button>
              </Col>
            </Row>
          </ShowForRole>
        </Card>
      </Col>
    </Row>
  );
}

export default SingleDevicePage;
