import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';

import LoadingButton from '../components/LoadingButton';
import EditWebhookPage from '../pages/EditWebhookPage';
import { fetchWebhooks, disableWebhook, enableWebhook, deleteWebhook } from '../features/webhooks/webhooksSlice';

function showFilters(w) {
  const device = w.device_id ? w.device_id : 'all';
  const eventType = w.event_type ? w.event_type : 'all';
  return (
    <>
      <strong>Device:</strong> {device}
      <br/>
      <strong>Event:</strong> {eventType}
    </>
  )
}

function WebhooksPage() {
  const dispatch = useDispatch();
  const requestStatus = useSelector(state => state.webhooks.status);
  const webhooks = useSelector(state => state.webhooks.webhooks);

  const match = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (requestStatus === 'idle') {
      dispatch(fetchWebhooks());
    }
  }, []);

  return (
    <>
      <Switch>
        <Route path={`${match.path}/new`}>
          <EditWebhookPage />
        </Route>
        <Route path={`${match.path}/:webhookId`}>
          <EditWebhookPage />
        </Route>
        <Route path={match.path}>
          <Row className="m-3">
            <div className="table-border w-100 bg-light">
              <Table hover className="mb-0 table-responsive-stack">
                <thead>
                  <tr className="d-flex">
                    <th className="col-12 col-lg-4 col-xl-4">URL</th>
                    <th className="col-12 col-lg-3 col-xl-4">FILTERS</th>
                    <th className="col-12 col-lg-5 col-xl-4">CONTROLS</th>
                  </tr>
                </thead>
                <tbody>
                  {webhooks.map((w) => (
                    <tr className="d-flex">
                      <td className="col-12 col-lg-4 col-xl-4">{w.url}</td>
                      <td className="col-12 col-lg-3 col-xl-4">{showFilters(w)}</td>
                      <td className="col-12 col-lg-5 col-xl-4">
                        {w.active ? 
                          <LoadingButton
                            isloading={w.loading}
                            onClick={() => dispatch(disableWebhook(w.webhook_id))}
                            variant="outline-danger"
                          >
                            DISABLE
                          </LoadingButton>
                          :
                          <LoadingButton 
                            isloading={w.loading}
                            onClick={() => dispatch(enableWebhook(w.webhook_id))}
                            variant="outline-secondary"
                          >
                            ENABLE
                          </LoadingButton>
                        }
                        <LoadingButton
                          className="ml-2"
                          isloading={w.loading}
                          onClick={() => dispatch(deleteWebhook(w.webhook_id))}
                          variant="outline-danger"
                        >
                          DELETE
                        </LoadingButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Row>
          <Row className="m-3">
            <Button variant="primary" onClick={() => history.push('/webhooks/new')}>CREATE</Button>
          </Row>
        </Route>
      </Switch>
        
    </>
  )
}

export default WebhooksPage;
